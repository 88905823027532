import {RouterModule, Routes} from '@angular/router';

import { AuthGuardService } from './auth-quard.service';
import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FailedAcsComponent } from './transactions/failed-acs/failed-acs.component';
import { FailedPaymentComponent } from './transactions/failed-payment/failed-payment.component';
import {ForgotPasswordBoxedComponent} from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { InvoicesComponent } from './invoices/invoices.component';
import {LoginBoxedComponent} from './DemoPages/UserPages/login-boxed/login-boxed.component';
import {NgModule} from '@angular/core';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import { ProfileComponent } from './profile/profile.component';
import { SuccesfulComponent } from './transactions/succesful/succesful.component';
import { ChooseCompanyComponent } from './DemoPages/UserPages/choose-company/choose-company.component';

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', canActivate: [AuthGuardService], pathMatch: 'full'},
  {
    path: '',
    component: BaseLayoutComponent,
    children: [

      // Dashboads
      {path: 'dashboard', component: DashboardComponent, data: {extraParameter: 'dashboardsMenu'}, canActivate: [AuthGuardService]},
      {path: 'transactions/succesful', component: SuccesfulComponent, data: {extraParameter: 'succesfulTransactionsMenu'}, canActivate: [AuthGuardService]},
      {path: 'transactions/failed-payment', component: FailedPaymentComponent, data: {extraParameter: 'succesfulTransactionsMenu'}, canActivate: [AuthGuardService]},
      {path: 'transactions/failed-acs', component: FailedAcsComponent, data: {extraParameter: 'succesfulTransactionsMenu'}, canActivate: [AuthGuardService]},
      {path: 'invoices', component: InvoicesComponent, data: {extraParameter: 'succesfulTransactionsMenu'}, canActivate: [AuthGuardService]},
      {path: 'company-details', component: ProfileComponent, data: {extraParameter: 'succesfulTransactionsMenu'}, canActivate: [AuthGuardService]},
      {path: 'integrations', component: IntegrationsComponent, data: {extraParameter: 'succesfulTransactionsMenu'}, canActivate: [AuthGuardService]},

    ]
    
  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [

      // User Pages

      {path: 'login', component: LoginBoxedComponent, data: {extraParameter: ''}},
      {path: 'choose-company', component: ChooseCompanyComponent, data: {extraParameter: ''}},
      {path: 'forgot-password', component: ForgotPasswordBoxedComponent, data: {extraParameter: ''}},
    ]
  },
  {path: '**', redirectTo: '/dashboard', canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
