import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiRoutesService } from 'src/app/api-routes.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styles: []
})
export class LoginBoxedComponent implements OnInit {

  public loginForm;
  constructor(private http: HttpClient, private formBuilder: FormBuilder, private apiRoutes: ApiRoutesService) {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(),
      password: new FormControl()
    });
  }

  ngOnInit() {
  }

  doLogin(formData) {

    return this.http.post(this.apiRoutes.doLoginUrl(), formData)
    .subscribe(
      response => {
        if (response['type'] == 'success') {
          sessionStorage.setItem('uid', response['message']['data']['id']);
          sessionStorage.setItem('access_token', response['message']['data']['access_token']);
        }
        window.location.href="/dashboard";
      },
      error => console.log('oops', error)
    );
  }

}
