import { Component, OnInit } from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { ApiRoutesService } from 'src/app/api-routes.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-failed-payment',
  templateUrl: './failed-payment.component.html',
  styleUrls: ['./failed-payment.component.sass']
})
export class FailedPaymentComponent implements OnInit {

  public transactionList;
  public selectedTransaction;
  public months;

  constructor(private http: HttpClient, private apiRoutes: ApiRoutesService, private modalService: NgbModal) { }

  ngOnInit() {
    this.months = [
      {value: '01' , label: 'Jan', selected: false},
      {value: '02' , label: 'Feb', selected: false},
      {value: '03' , label: 'Mar', selected: false},
      {value: '04' , label: 'Apr', selected: false},
      {value: '05' , label: 'May', selected: false},
      {value: '06' , label: 'June', selected: false},
      {value: '07' , label: 'July', selected: false},
      {value: '08' , label: 'Aug', selected: false},
      {value: '09' , label: 'Sept', selected: false},
      {value: '10' , label: 'Oct', selected: false},
      {value: '11' , label: 'Nov', selected: false},
      {value: '12' , label: 'Dec', selected: false},
    ];
    this.months[(new Date().getMonth())].selected = true;

    this.transactionList = false;
    this.selectedTransaction = false;

    return this.http.get(this.apiRoutes.getTransactionsUrl(['type=failed-payment']))
    .subscribe(
      response => {
        if (response['type'] == 'success') {
          console.log(response);
          this.transactionList = response['message']['data']['transactions'];
        }
      },
      error => console.log('oops', error)
    );
  }

  updateList(month) {
    return this.http.get(this.apiRoutes.getTransactionsUrl(['type=failed-payment', 'month='+month]))
    .subscribe(
      response => {
        if (response['type'] == 'success') {
          console.log(response);
          this.transactionList = response['message']['data']['transactions'];
        }
      },
      error => console.log('oops', error)
    );
  }

  showTransDetails(content, transId) {
    return this.http.get(this.apiRoutes.getTransactionUrl(transId))
      .subscribe(
        response => {
          if (response['type'] == 'success') {
            this.selectedTransaction = response['message']['data'];
            this.modalService.open(content, {
              size: 'lg'
            });
          }
        },
        error => console.log('oops', error)
      );
    }
}
