import { CanActivate, Router } from '@angular/router';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(public router: Router) {}
  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['login']);
      // TODO change this to false once done coding
      return false;
    }
    return true;
  }
  isAuthenticated(): boolean {
    const token = sessionStorage.getItem('access_token');
    const uid = sessionStorage.getItem('uid');
    if (token && uid) {
      return true;
    } else {
      return false;
    }
  }
}