import {
  Component,
  OnInit
} from '@angular/core';

import {
  ApiRoutesService
} from 'src/app/api-routes.service';
import {
  Color
} from 'ng2-charts/ng2-charts';
import {
  HttpClient
} from '@angular/common/http';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
})
export class AnalyticsComponent implements OnInit {

  heading = 'Analytics Dashboard';
  subheading = 'This is an example dashboard created using build-in elements and components.';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';

  slideConfig6 = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };

  public datasets = [{
    label: 'My First dataset',
    data: [65, 59, 80, 81, 46, 55, 38, 59, 80],
    datalabels: {
      display: false,
    },

  }];

  public datasets2 = [{
    label: 'My First dataset',
    data: [46, 55, 59, 80, 81, 38, 65, 59, 80],
    datalabels: {
      display: false,
    },

  }];

  public datasets3 = [{
    label: 'My First dataset',
    data: [65, 59, 80, 81, 55, 38, 59, 80, 46],
    datalabels: {
      display: false,
    },

  }];
  public lineChartColors: Color[] = [{ // dark grey
    backgroundColor: 'rgba(247, 185, 36, 0.2)',
    borderColor: '#f7b924',
    borderCapStyle: 'round',
    borderDash: [],
    borderWidth: 4,
    borderDashOffset: 0.0,
    borderJoinStyle: 'round',
    pointBorderColor: '#f7b924',
    pointBackgroundColor: '#fff',
    pointHoverBorderWidth: 4,
    pointRadius: 6,
    pointBorderWidth: 5,
    pointHoverRadius: 8,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#f7b924',
  }, ];

  public lineChartColors2: Color[] = [{ // dark grey
    backgroundColor: 'rgba(48, 177, 255, 0.2)',
    borderColor: '#30b1ff',
    borderCapStyle: 'round',
    borderDash: [],
    borderWidth: 4,
    borderDashOffset: 0.0,
    borderJoinStyle: 'round',
    pointBorderColor: '#30b1ff',
    pointBackgroundColor: '#ffffff',
    pointHoverBorderWidth: 4,
    pointRadius: 6,
    pointBorderWidth: 5,
    pointHoverRadius: 8,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#ffffff',
    pointHoverBorderColor: '#30b1ff',
  }, ];

  public lineChartColors3: Color[] = [{ // dark grey
    backgroundColor: 'rgba(86, 196, 121, 0.2)',
    borderColor: '#56c479',
    borderCapStyle: 'round',
    borderDash: [],
    borderWidth: 4,
    borderDashOffset: 0.0,
    borderJoinStyle: 'round',
    pointBorderColor: '#56c479',
    pointBackgroundColor: '#fff',
    pointHoverBorderWidth: 4,
    pointRadius: 6,
    pointBorderWidth: 5,
    pointHoverRadius: 8,
    pointHitRadius: 10,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#56c479',
  }, ];

  public labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];

  public options = {
    layout: {
      padding: {
        left: 0,
        right: 8,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false
        }
      }],
      xAxes: [{
        ticks: {
          display: false
        },
        gridLines: {
          display: false
        }
      }]
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false
  };
  stats: any;

  constructor(private http: HttpClient, private apiRoutes: ApiRoutesService, private modalService: NgbModal) {

  }

  ngOnInit() {
    return this.http.get(this.apiRoutes.getDashboardDataUrl())
      .subscribe(
        response => {
          if (response['type'] == 'success') {
            this.stats = response['message']['data'];
          }
        },
        error => console.log('oops', error)
      );
  }

}