import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';

import { ApiRoutesService } from '../api-routes.service';
import {
  HttpClient
} from '@angular/common/http';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit {
  private uid;
  private accessToken;
  public pageForm;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private apiRoutes: ApiRoutesService) {
    
  }

  ngOnInit() {
    
    this.uid = sessionStorage.getItem('uid');
    this.accessToken = sessionStorage.getItem('access_token');

    let FormGroup = {
      owner_name:  ["", Validators.required],
      county:  ["", Validators.required],
      city:  ["", Validators.required],
      address:  ["", Validators.required],
      cui:  ["", Validators.required],
      registration_id:  ["", Validators.required],
      iban:  ["", Validators.required],
      bank:  ["", Validators.required],
      consent:  ["", Validators.required]
    }
    this.pageForm = this.formBuilder.group(FormGroup);


    return this.http.get(this.apiRoutes.getProfileDetailsLink())
    .subscribe(
      response => {
        if (response['type'] == 'success') {
          console.log(response);
          if (response['message']['data']) {
            var data = response['message']['data'];
            for (let key in data) {
              var excluded = ['id', 'created_at', 'updated_at'];
              if (excluded.indexOf(key) < 0) {
                this.pageForm.get(key).patchValue(data[key]);
              }
            }
          }
        }
      },
      error => console.log('oops', error)
    );
  }

  doUpdate(pageForm) {
    return this.http.put(this.apiRoutes.updateProfileDetailsLink(pageForm.component), pageForm)
    .subscribe(
      response => {
       alert('Updated');
      },
      error => console.log('oops', error)
    );
  }
}