import { Component, OnInit } from '@angular/core';

import { ApiRoutesService } from '../api-routes.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.sass']
})
export class InvoicesComponent implements OnInit {
  public invoicesList;
  constructor(private http: HttpClient, private apiRoutes: ApiRoutesService) { }

  ngOnInit() {
    this.invoicesList = false;

    return this.http.get(this.apiRoutes.getInvoicesUrl())
    .subscribe(
      response => {
        if (response['type'] == 'success') {
          this.invoicesList = response['message']['data'];
        }
      },
      error => console.log('oops', error)
    );
  }

  getDownloadLink(invoiceId) {
    return this.apiRoutes.getInvoiceUrl(invoiceId);
  }

}
