import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiRoutesService {

  private urlAuth: string;

  constructor(
      public http: HttpClient
    ) {
        let uid = sessionStorage.getItem('uid');
        let accessToken = sessionStorage.getItem('access_token');
        this.urlAuth = '?uid=' + uid + '&access_token=' + accessToken;
    }

    public doLoginUrl() {
        return API_URL + 'user/login' ;
    }

    public getTransactionsUrl(filterParams) {
        let paramsQuery = filterParams.join('&');
        return API_URL + 'transaction-logs' + this.urlAuth + '&' + paramsQuery;
    }
    public getTransactionUrl(transactionId) {
        return API_URL + 'transaction-logs/' + transactionId + this.urlAuth;
    }

    public getDashboardDataUrl() {
        return API_URL + 'dashboard-data' + this.urlAuth;
    }


    public addUser() {
        return API_URL + 'add-user' + this.urlAuth;
    }

    public removeUser() {
        return API_URL + 'remove-user' + this.urlAuth;
    }

    public getAccessUsers() {
        return API_URL + 'access-users' + this.urlAuth;
    }

    public openGateUrl() {
        return API_URL + 'open-gate' + this.urlAuth;
    }

    public getInvoicesUrl() {
        return API_URL + 'invoices' + this.urlAuth;
    }
    public getInvoiceUrl(id) {
        return API_URL + 'invoices/' + id + this.urlAuth;
    }

    public getProfileDetailsLink() {
        return API_URL + 'parking-owners/default' + this.urlAuth;
    }
    public updateProfileDetailsLink(entity) {
        return API_URL + 'parking-owners/' + entity + this.urlAuth;
    }

    public getCompanyDetailsLink() {
        return API_URL + 'commercial-parkings/default' + this.urlAuth;
    }
    public updateCompanyDetailsLink(entity) {
        return API_URL + 'commercial-parkings/' + entity + this.urlAuth;
    }

   
}
