import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-company',
  templateUrl: './choose-company.component.html',
  styles: []
})
export class ChooseCompanyComponent implements OnInit {
  userCompanies : any;

  constructor() {
    this.userCompanies = [
      {'name' : 'CBC', 'id' : 1616},
      {'name' : 'Iulius Mall', 'id' : 1916}
    ];
  }

  ngOnInit() {
  }

}
