import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ApiRoutesService } from '../api-routes.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.sass']
})
export class IntegrationsComponent implements OnInit {

  private uid;
  private accessToken;
  public parkingForm;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private apiRoutes: ApiRoutesService) {
    
  }

  ngOnInit() {
    
    this.uid = sessionStorage.getItem('uid');
    this.accessToken = sessionStorage.getItem('access_token');

    let FormGroup = {
      parking_name:  ["", Validators.required],
      county:  ["", Validators.required],
      city:  ["", Validators.required],
      street:  ["", Validators.required],
      street_number:  ["", Validators.required],
      lat:  ["", Validators.required],
      long:  ["", Validators.required],
      radius: ["25", Validators.required],
      number_total_parkings:  ["", Validators.required]
    }
    this.parkingForm = this.formBuilder.group(FormGroup);


    return this.http.get(this.apiRoutes.getCompanyDetailsLink())
    .subscribe(
      response => {
        if (response['type'] == 'success') {
          console.log(response);
          if (response['message']['data']) {
            var data = response['message']['data'];
            for (let key in data) {
              var excluded = ['id', 'created_at', 'updated_at', 'parking_owner_id'];
              if (excluded.indexOf(key) < 0) {
                this.parkingForm.get(key).patchValue(data[key]);
              }
            }
          }
        }
      },
      error => console.log('oops', error)
    );
  }

  doUpdate(parkingForm) {
    return this.http.put(this.apiRoutes.updateCompanyDetailsLink(parkingForm.component), parkingForm)
    .subscribe(
      response => {
       alert('Updated');
      },
      error => console.log('oops', error)
    );
  }

}
