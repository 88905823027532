import { Component, OnInit } from '@angular/core';

import { ApiRoutesService } from '../api-routes.service';
import { Color } from 'ng2-charts';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  
  constructor(private http: HttpClient, private apiRoutes: ApiRoutesService, private modalService: NgbModal, private formBuilder: FormBuilder ) {

  }
  public datasets = [
    {
      label: 'My First dataset',
      data: [65, 59, 80, 81, 46, 55, 38, 59, 80],
      datalabels: {
        display: false,
      },

    }
  ];
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'rgba(247, 185, 36, 0.2)',
      borderColor: '#f7b924',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 4,
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#f7b924',
      pointBackgroundColor: '#fff',
      pointHoverBorderWidth: 4,
      pointRadius: 6,
      pointBorderWidth: 5,
      pointHoverRadius: 8,
      pointHitRadius: 10,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#f7b924',
    },
  ];
  
  public labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];

  public options = {
    layout: {
      padding: {
        left: 0,
        right: 8,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false
        }
      }],
      xAxes: [{
        ticks: {
          display: false
        },
        gridLines: {
          display: false
        }
      }]
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false
  };
  stats: any;
  accessUsers: any = [];
  pageForm;

  ngOnInit() {
    let FormGroup = {
      email_or_phone:  ["", Validators.required]
    }
    this.pageForm = this.formBuilder.group(FormGroup);

    this.http.get(this.apiRoutes.getDashboardDataUrl())
      .subscribe(
        response => {
          if (response['type'] == 'success') {
            this.stats = response['message']['data'];
          }
        },
        error => console.log('oops', error)
      );

    this.http.get(this.apiRoutes.getAccessUsers())
      .subscribe(
        response => {
          if (response['type'] == 'success') {
            this.accessUsers = response['message']['data'];
          }
        },
        error => console.log('oops', error)
      );
  }

  deleteUser(email) {
    return this.http.post(this.apiRoutes.removeUser(), {'email_or_phone' : email})
    .subscribe(
      response => {
       alert('Utilizator sters cu succes. Pagina se va reincarca');
       window.location.reload();
      },
      error => {
        alert('A aparut o eroare va rugam reincercati');
        console.log('oops', error)
      }
    );
  }

  addUser(pageForm) {
    return this.http.post(this.apiRoutes.addUser(), pageForm)
    .subscribe(
      response => {
       alert('Utilizator adaugat cu succes. Pagina se va reincarca.');
       window.location.reload();
      },
      error => {
        alert('A aparut o eroare va rugam reincercati');
        console.log('oops', error)
      }
    );
  }

  openGate() {
    return this.http.get(this.apiRoutes.openGateUrl())
      .subscribe(
        response => {
          if (response['type'] == 'success') {
            this.stats = response['message']['data'];
          }
        },
        error => console.log('oops', error)
      );
    
  }

}